<style lang="less" scoped>
.design-product {
  width: 100%;
  min-height: 800px;
  padding-top: 130px;
  background: #070911;
  position: relative;
  &-header {
    width: 100%;
    max-width: 1104px;
    height: 60px;
    margin: 0 auto;
    background: rgb(15, 17, 24);
    display: flex;
    justify-content: flex-start;
    &-item {
      width: 150px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: rgba(222, 230, 255, 1);
    }
    .actived {
      font-size: 14px;
      font-weight: 600;
      color: rgba(85, 119, 224, 1);
      background: rgba(30, 33, 43, 1);
      border-bottom: 2px solid rgba(85, 119, 224, 1);
    }
  }
  &-price {
    width: 100%;
    height: 360px;
    max-width: 1104px;
    margin: 30px auto 0;
    padding-top: 0.1px;
    background: rgb(15, 17, 24);
    &-container {
      width: 95.8%;
      height: 87.5%;
      margin: 2% 2.2%;
      position: relative;
      border: 2px solid;
      border-image: linear-gradient(
          173deg,
          rgba(248, 40, 108, 1),
          rgba(249, 98, 47, 1)
        )
        2 2;
      clip-path: inset(0 round 5px);
      img {
        width: 260px;
        height: 180px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-main {
    width: 100%;
    height: 100%;
    min-height: 800px;
    max-width: 1104px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: 30%;
      max-width: 348px;
      height: 200px;
      position: relative;
      margin-top: 16px;
      color: rgb(255, 255, 255);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      &-hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        background: rgba(7, 9, 17, 0.59);
        &-title {
          margin-top: 30px;
          margin-right: 36px;
          text-align: right;
          img {
            width: 14px;
          }
        }
        &-main {
          width: 80%;
          height: 43px;
          margin-left: 10%;
          // margin-top: 17%;
          margin-top: 32%;
          display: flex;
          &-left {
            width: 20%;
            img {
              width: 38px;
              height: 38px;
            }
          }
          &-right {
            width: 80%;
            text-align: left;
            p:nth-child(1) {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 1px;
              margin: 0;
            }
            p:nth-child(2) {
              font-size: 10px;
              letter-spacing: 0px;
            }
          }
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="design-product">
    <div class="design-product-header">
      <div class="design-product-header-item"
           v-for="(item, index) in designProductNav"
           :key="index"
           @click="changeModal(item.type)"
           :class="index === activedIndex ? 'actived' : ''">
        <span>
          {{ $t(item.name) }}
        </span>
      </div>
    </div>
    <div class="design-product-price">
      <div class="design-product-price-container">
        <img :src='showImg'
             alt="">
      </div>
    </div>
    <div class="design-product-main">
      <div class="design-product-main-item"
           v-for="(item, index) in topBrandsList"
           :key="index"
           @click="toCompanyHome(item)">
        <img :src="'/api/static/'+item.detail.previewUrl"
             alt="">
        <div class="design-product-main-item-hover">
          <div class="design-product-main-item-hover-main">
            <div class="design-product-main-item-hover-main-left">
              <img :src="'/api/static/'+item.detail.logoImg"
                   alt="">
            </div>
            <div class="design-product-main-item-hover-main-right">
              <p>
                {{item.brand_name}}
              </p>
              <p>
                {{item.company_name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Common-activies :style="{maxWidth: '1104px', margin: '0 auto'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';

export default {
  name: 'design-product',
  data () {
    return {
      activedIndex: 0,
      designProductType: 'city_impression',
      designProductNav: [
        {
          name: 'Q31',
          type: 'city_impression',
          img: require('../assets/price-h1.png')
        },
        {
          name: 'Q32',
          type: 'original',
          img: require('../assets/price-h5.png')
        },
        {
          name: 'Q33',
          type: 'annual_city',
          img: require('../assets/price-h3.png')
        },
        {
          name: 'Q34',
          type: 'best_online',
          img: require('../assets/price-h2.png')
        },
        {
          name: 'Q35',
          type: 'recommendation',
          img: require('../assets/price-h4.png')
        },
        {
          name: 'Q36',
          type: 'hot_comment',
          img: require('../assets/price-h6.png')
        }
      ],
      topBrandsList: [],
      showImg: require('../assets/price-h1.png')
    }
  },
  created () {
    if (this.$route.params.type) {
      this.changeModal(this.$route.params.type);
    } else {
      this.getSelection();
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toCompanyHome (item) {
      if (!this.$Cookies.get('cecc-user')) {
        this.$router.push({
          name: 'login',
          params: {
            redirect: 'fairviewJiangnan'
          }
        })
      } else {
        this.$router.push({
          name: 'companyPreview',
          params: {
            from: 'jiangnan',
            price: this.designProductType,
            brandObj: item.detail
          }
        })
      }
    },
    changeModal (type) {
      this.designProductType = type;
      this.activedIndex = this.designProductNav.findIndex(item => item.type === type);
      let findIndex = this.designProductNav.findIndex(item => item.type === this.designProductType);
      if (findIndex > -1) {
        this.showImg = this.designProductNav[findIndex].img;
      }
      this.getSelection();
    },
    getSelection () {
      this.$api.getSelections({
        category: this.designProductType,
        city: this.activedIndex === 2 ? '331100' : null
      }).then(res => {
        this.topBrandsList = res.selections;
      })
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  }
}
</script>
