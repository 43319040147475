<style lang="less" scoped >
.about-us-fifth {
  width: 100%;
  height: 442px;
  position: relative;
  margin-top: 130px;
  &-container {
    width: 100%;
    height: 100%;
    // max-width: 1104px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgba(222, 230, 255, 1);
    p:nth-child(1) {
      width: 100%;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 4px;
    }
    p:nth-child(2) {
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 4px;
      margin-bottom: 64px;
    }
  }
  &-container-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-item {
      width: 20%;
      position: relative;
      &:hover {
        .img-hover {
          display: block;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
      }
      .img-hover {
        display: none;
        width: 100%;
        height: 37%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(7, 9, 17, 0.8);
        p {
          font-family: "PingFangSC-Regular", "PingFang SC";
          font-weight: 400;
          color: rgba(222, 230, 255, 1);
          margin: 0;
          padding-left: 20px;
          text-align: left;
          &:nth-child(1) {
            font-size: 20px;
          }
          &:nth-child(2) {
            font-size: 12px;
          }
        }
      }
    }
  }
  &-container-wrapper {
    margin-top: 68px;
    color: rgba(222, 230, 255, 1);
    &-first {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-left {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
      &-right {
        width: 68px;
        height: 24px;
        cursor: pointer;
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid rgba(222, 230, 255, 1);
      }
    }
    &-second {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      height: 28px;
      &-left {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      &-right {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
</style>
<template>
  <div class="common-activies">
    <div class="about-us-fifth">
      <div class="about-us-fifth-container">
        <div class="about-us-fifth-container-wrapper"
             v-show="$Cookies.get('cecc-lang') !== 'en'">
          <div class="about-us-fifth-container-wrapper-first">
            <div class="about-us-fifth-container-wrapper-first-left">
              新闻动态
            </div>
            <div class="about-us-fifth-container-wrapper-first-right"
                 @click="linkTo('moreNews')">
              MORE
            </div>
          </div>
          <div class="about-us-fifth-container-wrapper-second"
               v-for="(item, index) in newsList"
               :key="index"
               @click="linkToNews(item)">
            <div class="about-us-fifth-container-wrapper-second-left">
              {{item.title}}
            </div>
            <div class="about-us-fifth-container-wrapper-second-right">
              {{ item.created.split(' ')[0] }} &nbsp; &nbsp; >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-activies',
  props: {
    msg: String
  },
  data () {
    return {
      newsList: []
    }
  },
  created () {
    this.$api.getArticles({
      category: 'news',
      num: 5
    }).then(res => {
      this.newsList = res.articles;
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    linkToNews (item) {
      if (item.source === 0) {
        this.$router.push({
          name: 'news',
          params: item
        })
      } else {
        this.linkTo(item.link, '_blank');
      }
    }
  }
}
</script>
